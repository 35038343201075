import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteVideoById,
  getDataList,
  getPlayableStream,
  syncVideoCDNStatus,
  toggleFeatured,
  toggleStatus,
} from '../../services/content.service';
import {
  ACTIONS,
  ASSET_TYPE,
  CONTENT_ALIAS,
  MULTIPLE_CAT_PER_VIDEO,
} from '../../config/const.config';
import AddEditVideos from './add-edit-videos.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewVideos from './view-videos.component';
import AssignCategoryVideos from './assign-category-videos.component';
import VideoPreview from './videos-preview.component';
import ImagePreview from './image-preview.component';
import UploadAssets from './upload-assets.component';
import LanguageWiseUploadAssets from './language-assets-upload.component';
import LanguageWiseBulkUploadAssets from './language-assets-bulk-upload.component';
import SearchList from '../table-elements/search-list.component';
import TranslateLessons from './translate-lessons.component';
import Categories from '../form-elements/categories.component';

const ListVideos = ({ videoContentType }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: 0,
    },
  });
  const categoryWatch = watch('category');

  const renderColumns = [];
  columns.forEach((item) => {
    if (videoContentType === 'ads') {
      if (
        item.dataKey !== 'thumbnail' &&
        item.dataKey !== 'categoryName' &&
        item.dataKey !== 'mp3Status' &&
        item.dataKey !== 'isFeatured'
      ) {
        renderColumns.push(item);
      }
    } else if (videoContentType === 'videos') {
      if (item.dataKey !== 'id' && item.dataKey !== 'adPlaysCount') {
        renderColumns.push(item);
      }
    } else {
      renderColumns.push(item);
    }
  });

  const renderActions = [];
  actions.forEach((item) => {
    if (videoContentType === 'ads') {
      if (
        item.action !== 'assign_books' &&
        item.action !== 'upload_slides' &&
        item.action !== 'upload_podcasts' &&
        item.action !== 'upload_transcript_pdf' &&
        item.action !== 'upload_transcript_docx' &&
        item.action !== 'zh_assets' &&
        item.action !== 'ru_assets' &&
        item.action !== 'en_assets' &&
        item.action !== 'translate'
      ) {
        renderActions.push(item);
      }
    } else if (videoContentType === 'videos') {
      if (MULTIPLE_CAT_PER_VIDEO && item.action === 'assign_books') {
        renderActions.push(item);
      } else if (item.action !== 'assign_books') {
        renderActions.push(item);
      }
    }
  });

  const searchVideosRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
    category: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoPlayer, setVideoPlayer] = useState({
    show: false,
    videoUrl: '',
    title: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });
  const [previousContentType, setPreviousContentType] =
    useState(videoContentType);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
      q: searchVideosRef.current.value,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteVideoById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleClickEvent = (video) => {
    let uri = `videoID=${video.id}`;
    uri += `&videoContentType=${videoContentType}`;

    getPlayableStream(uri)
      .then((res) => {
        setVideoPlayer({
          ...videoPlayer,
          show: true,
          title: video.title,
          videoUrl: res.data.videoUrl,
        });
      })
      .catch(() =>
        setTimeout(() => {
          setVideoPlayer({
            ...videoPlayer,
            show: false,
            title: '',
            videoUrl: '',
          });
        }, 5000)
      );
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.thumbnail,
      title: rowData.title,
    });
  };

  const handleSyncCDNStatus = (contentType) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    syncVideoCDNStatus(contentType)
      .then((res) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${res.data?.updatedRowsCount} data updated.`,
        });
        setOptions({ ...options, reloadCounter: options.reloadCounter + 1 });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'video',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleToggleFeatured = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      isFeatured: value ? 1 : 0,
    };
    toggleFeatured(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `Featured ${CONTENT_ALIAS.VIDEO_SINGULAR} updated.`,
        });
        handleRefreshData();
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
      loading: true,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchVideosRef.current.value = '';
  };

  const dataListAPICall = () => {
    let uri = 'videos';
    uri += `?type=${videoContentType}`;
    uri += `&page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }
    if (categoryWatch && Number(categoryWatch)) {
      uri += `&category=${categoryWatch}`;
    }
    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        if (window.location.pathname.indexOf(res.data.type) >= 0) {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    if (previousContentType !== videoContentType) {
      options.page = 0;
      options.rows = [];
      options.totalRows = 0;
      options.loading = true;
      options.q = null;
      options.sortBy = null;
      options.searchValue = '';
      setOptions({
        ...options,
        page: 0,
        rows: [],
        totalRows: 0,
        q: null,
        searchValue: '',
      });
      setPreviousContentType(videoContentType);
      if (searchVideosRef.current?.value) {
        searchVideosRef.current.value = null;
      }
    }

    dataListAPICall();
  }, [videoContentType, options.reloadCounter, categoryWatch]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="fe:sync" />}
            sx={{
              marginLeft: '5px',
            }}
            onClick={() => handleSyncCDNStatus(videoContentType)}
          >
            Sync CDN Status
          </Button>

          {videoContentType === 'videos' && (
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Categories
                  id="category"
                  name="category"
                  label={`All ${CONTENT_ALIAS.CATEGORY_PLURAL}`}
                  defaultValue={value}
                  onChange={onChange}
                  sx={{
                    width: '200px',
                    height: '36px',
                  }}
                  parentSx={{
                    width: '200px',
                    height: '36px',
                    marginLeft: '5px',
                  }}
                  labelSx={{
                    transform:
                      !categoryWatch &&
                      'translate(14px, 8px) scale(1) !important',
                  }}
                  error={errors?.category?.message || ''}
                  options={options}
                  addDefault
                  menuProps={{ PaperProps: { sx: { width: '500px' } } }}
                />
              )}
            />
          )}

          <SearchList
            label={
              videoContentType === 'videos'
                ? CONTENT_ALIAS.VIDEO_PLURAL
                : CONTENT_ALIAS.AD_PLURAL
            }
            handleSearch={handleSearchVideos}
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={renderColumns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          onCustomClick={handleClickEvent}
          handleImagePreview={handleImagePreview}
          handleToggleStatus={handleToggleStatus}
          handleToggleFeatured={handleToggleFeatured}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditVideos
          title={`Add New ${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
          videoContentType={videoContentType}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditVideos
          title={`Edit ${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
          videoContentType={videoContentType}
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateLessons
          title={`Translate ${CONTENT_ALIAS.VIDEO_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewVideos
          title={`${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          videoContentType={videoContentType}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }`}
          message={`Do you want to delete ${doAction.data.title} ${
            videoContentType === 'videos'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.ASSIGN_CATEGORIES.value && (
        <AssignCategoryVideos
          title={`Assign ${CONTENT_ALIAS.CATEGORY_PLURAL} to ${doAction.data.title} ${CONTENT_ALIAS.VIDEO_SINGULAR}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_MP4.value && (
        <UploadAssets
          title={`Upload MP4 - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          videoContentType={videoContentType}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_SLIDES.value && (
        <LanguageWiseUploadAssets
          title={`Upload Slides - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          assetType={ASSET_TYPE.SLIDES}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_PODCASTS.value && (
        <LanguageWiseBulkUploadAssets
          title={`Upload Podcasts - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          assetType={ASSET_TYPE.PODCAST}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_TRANSCRIPT_PDF.value && (
        <LanguageWiseBulkUploadAssets
          title={`Upload Transcript (PDF) - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          assetType={ASSET_TYPE.TRANSCRIPT_PDF}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_TRANSCRIPT_DOCX.value && (
        <LanguageWiseBulkUploadAssets
          title={`Upload Transcript (Docx) - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          assetType={ASSET_TYPE.TRANSCRIPT_DOCX}
        />
      )}

      {videoPlayer.show && (
        <VideoPreview
          videoPlayer={videoPlayer}
          setVideoPlayer={setVideoPlayer}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListVideos.propTypes = {
  videoContentType: PropTypes.string.isRequired,
};

export default ListVideos;
