import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { viewVideoById } from '../../services/content.service';
import { ASSET_TYPE, CONTENT_ALIAS } from '../../config/const.config';
import AudioPdfUpload from './audio-pdf-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const LanguageWiseBulkUploadAssets = ({
  title,
  dataId,
  onCancel,
  assetType,
}) => {
  const [videoAssetData, setVideoAssetData] = useState({
    podcastUrl: '',
    transcriptUrl: '',
    transcriptDocxUrl: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [renderImage, setRenderImage] = useState(false);

  const showUpdatedImage = (rerender) => {
    setRenderImage(rerender);
  };

  const showToastMsg = (msgType, message) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    viewVideoById(dataId)
      .then((res) => {
        setVideoAssetData({
          ...videoAssetData,
          podcastUrl: res.data?.podcastUrl || '',
          transcriptUrl: res.data?.transcriptUrl || '',
          transcriptDocxUrl: res.data?.transcriptDocxUrl || '',
        });
      })
      .catch(() => {
        setVideoAssetData({
          ...videoAssetData,
          podcastUrl: '',
          transcriptUrl: '',
          transcriptDocxUrl: '',
        });
      });
  }, [renderImage]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="view-dialog-title">{`${CONTENT_ALIAS.VIDEO_PLURAL} - ${title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          {assetType === ASSET_TYPE.PODCAST && (
            <Box
              component="section"
              sx={{
                p: 3,
                border: '2px solid lightgrey',
                borderRadius: '5px',
                width: '100%',
                marginTop: 3,
              }}
            >
              <AudioPdfUpload
                dataId={dataId}
                dataTitle="Podcast"
                showToastMsg={showToastMsg}
                snackbarInfo={snackbarInfo}
                setSnackbarInfo={setSnackbarInfo}
                onSuccess={() => {}}
                assetType={ASSET_TYPE.PODCAST}
                videoAssetsData={videoAssetData}
                showUpdatedImage={showUpdatedImage}
                bulkUpload
              />
            </Box>
          )}
          {assetType === ASSET_TYPE.TRANSCRIPT_PDF && (
            <Box
              component="section"
              sx={{
                p: 3,
                border: '2px solid lightgrey',
                borderRadius: '5px',
                width: '100%',
                marginTop: 3,
              }}
            >
              <AudioPdfUpload
                dataId={dataId}
                dataTitle="Transcript (PDF)"
                showToastMsg={showToastMsg}
                snackbarInfo={snackbarInfo}
                setSnackbarInfo={setSnackbarInfo}
                onSuccess={() => {}}
                assetType={ASSET_TYPE.TRANSCRIPT_PDF}
                videoAssetsData={videoAssetData}
                showUpdatedImage={showUpdatedImage}
                bulkUpload
              />
            </Box>
          )}
          {assetType === ASSET_TYPE.TRANSCRIPT_DOCX && (
            <Box
              component="section"
              sx={{
                p: 3,
                border: '2px solid lightgrey',
                borderRadius: '5px',
                width: '100%',
                marginTop: 3,
              }}
            >
              <AudioPdfUpload
                dataId={dataId}
                dataTitle="Transcript (Docx)"
                showToastMsg={showToastMsg}
                snackbarInfo={snackbarInfo}
                setSnackbarInfo={setSnackbarInfo}
                onSuccess={() => {}}
                assetType={ASSET_TYPE.TRANSCRIPT_DOCX}
                videoAssetsData={videoAssetData}
                showUpdatedImage={showUpdatedImage}
                bulkUpload
              />
            </Box>
          )}
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

LanguageWiseBulkUploadAssets.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default LanguageWiseBulkUploadAssets;
